.krunk-leftparent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.krunk-leftbodyparent {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.krunk-leftbodyparent > img {
  margin-top: 160px;
  height: 400px;
  width: 250px;
}

.krunk-leftbodycontent {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  flex: 1.8;
}

.krunk-firstp {
  padding: 2px;
  margin-top: 35px;
}

.krunk-secondp {
  margin-top: 30px;
}

.krunk-thirdp {
  padding: 2px;
  margin-top: 30px;
}

.krunk-firstp > p {
  margin: 0;
  padding: 0;
  color: #79a1f1;
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 65px;
}

.krunk-secondp > p {
  text-align: left;
  padding: 0px;
  margin: 0px;
  color: #79a1f1;
  font-family: Poppins;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 65px;
}

.krunk-thirdp > p {
  text-align: left;
  padding: 0px;
  margin: 0px;
  color: #789fef;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px; /* 200% */
}
