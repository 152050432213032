.krunk-chat_text{

max-width: 250px;
padding: 10px 15px;
border-radius: 15px;


}
.krunk-chat_text p{
    width: 100%;
    padding: 10px; /* Adjust the padding as needed */
    margin: 0; /* Removes default margin if necessary */
    overflow-wrap: break-word; /* Wraps long words within the container */
    word-break: break-word;
    /* box-sizing: border-box */
}

.krunk-chat{

    display: flex;
    flex-direction: column;
    margin-bottom:25px;
    align-items: flex-start;
    color: var(--tailwind-buttontext);

font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;

}

.krunk-time_tag >p{
    margin: 0;;
    padding-left: 10px;
    color: var(--tailwind-buttontext);

font-family: Poppins;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
    
    }