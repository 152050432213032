.krunk-Parentchat {
  height: 340px;
  width: 360px;
  display: flex;
  flex-direction: column;
  border: 7px solid #fff;
  background: #fff;
  border-radius: 20px;
  justify-content: space-between;
  /* overflow: hidden; */
}
