.krunk-chatheaderparent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 20px 20px 0px 0px;
    padding: 10px 20px;
    align-items: center;
    height: 80px;
    background: #EFF3FD;
  }
  
  .krunk-chatheaderparentinfoname {
    margin-top: 30px;
  }
  
  .krunk-chatheaderparentprofile {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .krunk-chatheaderparentprofilepicture > img {
    background-color: white;
    margin-right: 10px;
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
  
  .krunk-chatheaderparentinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .krunk-chatheaderparentinfoname {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .krunk-chatheaderparentinfoname > p {
    margin-top: 0px;
    margin-bottom: 3px;
    margin-right: 10px;
    color: #4C82EF;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
  }
  
  .krunk-chatheaderparentinfochat {
    padding: 0;
    margin: 0;
  }
  
  .krunk-chatheaderparentinfochat > p {
    margin-top: 3px;
    margin-bottom: 0px;
    color: #4C82EF;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
  }
  
  .krunk-chatheaderparentonline {
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    flex-direction: row;
    align-items: last baseline;
    height: 40px;
  }
  
  .krunk-chatheaderparentonlineinner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .krunk-chatheaderparentonlineinner > p {
    padding-left: 5px;
    margin: 0;
    color: #4C82EF;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 10px;
  }
  
  .krunk-chatheaderparentonlineinner > svg {
    margin-top: 2px;
  }
  