.krunk-chat_text1 {
  max-width: 250px;
  padding: 10px 15px;
  border-radius: 15px;
  border: 1px solid #fff;
  background: var(--tailwind-bubblecolor);
}

.krunk-chat1 {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  align-items: flex-end;
  color: var(--tailwind-buttontext);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.krunk-time_tag1 {
  padding-left: 10px;
}

.krunk-time_tag1 > p {
  margin: 0;
  padding-right: 10px;
  color: var(--tailwind-buttontext);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
